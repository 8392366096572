/*Import font-awesome*/
@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
//@import "~font-awesome/css/font-awesome.min.css";
/*Import Core*/
@import "./core/reset-min";
@import "./core/fonts";
@import "./core/variables";
@import "./core/mixin";

* {
  font-family: "Avenir LT Std", "Helvetica Nueue", sans-serif;
}

html {
  font-size: 62.5%;
}

body {
  background-color: $color-white;
}

.container {
  background-color: $color-pure-white;
  width: 100%;
  height: 100%; // display: flex;
  overflow: scroll;
  // flex-direction: column;
}

//.bottom:after {
//  content: '';
//  position: absolute;
//  /* background-color: red; */
//  background-repeat: no-repeat;
//  background-position: -50px 0px;
//  background-size: 500px 500px;
//  /* background-repeat: no-repeat; */
//  bottom: 0;
//  right: 0;
//  width: 300px;
//  opacity: 0.08;
//  height: 400px;
//  background-image: url(https://www.reliancehmo.com/img/android-icon-192x192.png);
//}

.text_left {
  text-align: left;
}

.text_center {
  text-align: center;
}

.text_right {
  text-align: right;
}

.mb-2 {
  margin-bottom: 2rem;
}

button[disabled] {
  background-color: #999999;
  cursor: not-allowed;
}

.primary_button {
  border-radius: 4px;
  font-size: 1.4rem;
  font-style: normal;
  background-color: $color-mainblue;
  color: $color-white;
  margin: 1rem;
  padding: 7px 15px;
  border: 0;
  cursor: pointer;

  a {
    color: $color-white;
    text-decoration: none;
  }
}

.red {
  background-color: $color-red;
}

.green {
  background-color: $color-green;
}

.dashboard__primary-btn {
  background-color: $color-mainblue;
  color: $color-pure-white;
  padding: 1rem;
  font-weight: 600;
  font-size: 1.6rem;
  border: 0;
  border-radius: 4px;
  margin-right: 1rem;
  width: fit-content;
  cursor: pointer;

  @at-root a#{&} {
    font-size: 1.6rem;
    text-align: center;
    text-decoration: none;
    color: $color-pure-white;
    display: block;
  }
}

.dashboard__primary-btn:disabled {
  background-color: $brand-01-disabled;
}

.dashboard__secondary-btn {
  width: fit-content;
  border: 1.5px solid $color-mainblue;
  background-color: $color-pure-white;
  border-radius: 4px;
  margin-right: 1rem;
  color: $color-mainblue;
  text-decoration: none;
  font-size: 1.6rem;
  padding: 1rem;
  cursor: pointer;
  font-weight: 600;

  @at-root a#{&} {
    text-align: center;
    font-size: 1.6rem;
    text-decoration: none;
    color: $color-mainblue;
    display: block;
    text-align: center;
  }
}

.dashboard__secondary-btn:hover {
  background-color: $brand-01-hover;
  color: $color-pure-white;

  a {
    color: $color-pure-white;
  }
}

.dashboard__secondary-btn:focus {
  background-color: $brand-01-focus;
  color: $color-pure-white;
}

.dashboard-refer__primary-btn {
  @extend .dashboard__primary-btn;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 300px;
}

.dashboard-refer__secondary-btn {
  @extend .dashboard__secondary-btn;
  margin-bottom: 1em;
  width: 300px;
}
.dashboard__primary-btn--full-width {
  @extend .dashboard__primary-btn;
  display: block;
  width: 100%;
  margin-bottom: 25px;
}

.dashboard__secondary-btn--full-width {
  @extend .dashboard__secondary-btn;
  display: block;
  width: 100%;
  margin-bottom: 25px;
}

// New Table design
.dashboard-table-wrap {
  display: flex;
  flex-direction: column;
}

.dashboard-table-overflow {
  overflow-x: scroll;
}

.dashboard-table {
  width: 100%;
}

.dashboard-table {
  tr:nth-child(odd) {
    background-color: rgba(81, 153, 211, 0.03);
  }

  tr:hover {
    background-color: $color-white-08;
  }
}

.dashboard-table-item {
  height: 5rem;
  min-height: 5rem;
  font-size: 1.4rem;
  text-align: left;
  padding-left: 2rem;
  color: #212121;
  vertical-align: middle;
}

.dashboard-table-item__header {
  background-color: rgba(81, 153, 211, 0.04);
  font-weight: 800;
}

.dashboard-table-item__body {
  font-weight: 500;
  color: $color-black-03;
}

.new_feedback_form {
  min-width: 30rem;
  padding: 3rem;
}

.select_pharamacy_form {
  min-width: 30rem;
  padding: 3rem;
}

.button_link {
  background: none;
  border: none;
  color: $color-mainblue;
  font-size: 1.4rem;
  text-decoration: underline;
  padding: 0.5rem 0;
}

//login page css begins here.
.login_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 300;

  .form_wrapper {
    padding: 2rem;

    .form {
      width: 100%;
      height: 100%;
      text-align: center;
      margin-bottom: 8rem;

      input[type="text"],
      input[type="password"] {
        width: 100%;
        height: 6rem;
        padding: 2rem;
        border: 1px solid #c0e5e5;
        border-radius: 4px;
        box-sizing: border-box;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      button {
        border-radius: 4px;
        font-size: 1.6rem;
        width: 100%;
        height: 6.5rem;
        font-style: normal;
        font-weight: 500;
        background-color: $color-mainblue;
        color: $color-white;
        margin-top: 2rem;
        margin-bottom: 2rem;
        border: 0;
        cursor: pointer;
      }
    }
  }
}

.dashboard-list-item {
  height: 5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #e6f0f9;
  font-size: 1.6rem;
  color: $color-black;
  font-weight: 600;

  a {
    font-size: 1.6rem;
    color: $color-black;
    text-decoration: none;
    font-weight: 600;
  }
}

.dashboard-list-item:hover {
  background-color: $color-white-08;
}

.dashboard-control__button {
  font-weight: 800;
  font-size: 1.6rem;
  width: 14rem;
}

.infinite-scroll-btn {
  border-radius: 2rem;
  padding: 1rem 3rem;
  align-items: center;
  margin-bottom: 3rem;
  cursor: pointer;
  box-shadow: 0 0 3px 0px #ccc;
}

// dashboard css begins here
.dash {
  height: 100%;
  overflow: hidden;
  margin: 0;
  display: flex;
  box-sizing: border-box;
}

.column {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#left {
  flex-shrink: 0;
}

.top-left {
  flex-shrink: 0;
}

.top-right {
  flex-shrink: 0;
  display: inline-flex;
}

.bottom {
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.dashboard-content-background {
  background-color: $color-light-white;
  padding: 2rem;
}

.dashboard {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.sidebar {
  width: 20%;
  height: 100%;
  background-color: $color-pure-white;
  font-size: 1.6rem;
  min-width: 25rem;

  ul {
    width: 100%;
  }
}

.sidebar__top {
  height: 8rem;
  display: flex;
  padding: 1.5rem 2rem;
  align-items: center;
  background-color: $color-midblue;
  border-bottom: 1px solid rgba(33, 33, 33, 0.25);

  img {
    max-width: 100%;
    height: 50px;
  }
}

.sidebar--space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar--enrollee {
  background-color: $color-midblue;
}

.dashboard__menu {
  // height: 4rem;
  padding: 2rem;
  display: flex;
  border-left: 5px solid transparent;
  align-items: center;
  padding-left: 1.5rem;
  text-decoration: none;
  color: $color-pure-white;
  font-style: normal;
  font-weight: 300;

  .fas {
    margin-right: 2rem;
    color: $color-pure-white;
    font-size: 2rem;
    width: 3rem;
  }
}

.menu-active {
  border-left: 5px solid $color-bg-blue;
  background: rgba(9, 64, 99, 0.2);
}

.content {
  width: 100%;
  position: relative;
  // height: 100%;
  // background-color: #FFFFFF;
  // display: flex;
  // flex-direction: column; // min-width: 76.8rem;
}

.header {
  height: 8rem;
  background-color: $color-pure-white;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid rgba(9, 64, 99, 0.1);
}

.header__left {
  display: none;
  align-items: center;

  .toggle-nav {
    display: none;
  }
}

.header__title {
  h2 {
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    color: $color-mainblue;
  }
}

.header__right,
.add-family-member-button-container {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    border-radius: 4px;
    font-size: 1.6rem;
    padding: 0 1rem;
    min-width: 15rem;
    height: 4rem;
    font-style: normal;
    font-weight: normal;
    background-color: $color-mainblue;
    color: $color-pure-white;
    border: 0;
    margin: 0 1rem;
    cursor: pointer;
  }

  .header__notif {
    button {
      width: 2rem;
      height: 2rem;
    }

    .notif {
      border: 0; // height: 4rem;
      // width: 4rem;
      background-color: $color-pure-white;
      color: $color-mainblue;
    }
  }

  .header__search {
    width: 16rem;
    height: 4rem;
    input {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      border: 1px solid $color-mainblue;
      padding: 1rem;
    }
  }
}

.badge {
  position: relative;
}

.badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 0.8rem;
  background: #d72c2c;
  color: white;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  border-radius: 50%; // box-shadow: 0 0 1px #333;
  font-style: normal;
  font-weight: normal;
}

.active {
  display: flex;
  flex-direction: column;
}

.fa {
  color: $color-mainblue;
}

// .tips {
//   display: flex; // justify-content: space-around;
//   width: 100%;
//   min-width: 32rem;
//   margin-top: 0.5rem;
//   background-color: #ffffff;
//   padding-right: 0.5rem;
//   padding-left: 0.5rem;
//   flex-flow: wrap;
// }

// Tip Page
// .article-wrapper {
//   //width: 80%;
// }

.article__title {
  color: $color-black;
  font-size: 2.8rem;
  line-height: 3.2rem;
  margin-bottom: 2rem;
}

.article__img {
  width: 100%;
  height: 22rem;
  margin: 2rem 0;

  img {
    max-width: 100%;
    height: inherit;
  }
}

.article__text {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #333;
  text-align: justify;

  p {
    float: none;
  }

  img {
    max-width: 40%;
    float: left;
    margin-right: 2rem;
  }
}

.article__text--bold {
  font-weight: 800;
}

.article__btn {
  @extend .dashboard-control__button;
  margin-top: 2rem;

  a {
    color: $color-pure-white;
    font-size: 1.6rem;
    text-decoration: none;
  }
}

// Tips

.tips-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  flex-flow: wrap;
}

.tips__time-date-stamp {
  font-size: 1.2rem;
  color: #a5a5a5;
}

// .tips__card {
//   border-radius: 4px;
//   width: 32%;
//   min-width: 30rem;
//   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10);
//   background-color: $color-white;
//   margin: 0.5rem;
// }

.tips {
  width: 32%;
  cursor: pointer;
  margin-bottom: 2rem;
}

.tips__image {
  width: 100%;
  min-height: 14rem;
}

.tips__textarea {
  padding: 1rem;
}

// .tips__title {
//   font-size: 1.6rem;
//   font-weight: normal;
//   font-style: normal;
//   color: $color-mainblue;
//   line-height: 2.0rem;
// }

.tips__title {
  font-size: 1.6rem;
  margin: 1rem 0;
  color: $color-black;
}

.tips__preview {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.6rem;
  color: #333333;
  margin-bottom: 1rem;
}

.tips__textarea--button {
  border-top: 0.5px solid #e5e5e5;
  padding-top: 1rem;

  button {
    background-color: $color-white;
    font-size: 1.2rem;
    font-weight: normal;
    font-style: normal;
    color: $color-midblue;
    padding-right: 1rem;
    border: 0;
  }

  a {
    color: $color-midblue;
    text-decoration: none;
  }
}

// .hospital_list, .supportchat_list {
//   width: 100%;
//   text-align: left;
//   th {
//     padding: 1.5rem 2rem;
//     font-size: 1.4rem;
//     border-bottom: 1px solid #e3e3e3;
//     font-style: normal;
//     font-weight: normal;
//     color: $color-midblue;
//     height: 6rem;
//     vertical-align: middle;
//   }

//   td {
//     padding: 1.5rem 2rem;
//     font-size: 1.6rem;
//     border-bottom: 1px solid #e3e3e3;
//     font-style: normal;
//     font-weight: 300;
//     color: $color-black;
//     height: 6rem;
//     vertical-align: middle;
//   }

//   tbody:hover {
//     background-color: #f5f5f5;
//   }
// }

.dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 4px solid;
  display: flex;
}

.sc_active {
  color: green;
}

.sc_dead {
  color: $color-red;
}

//signup form css begins here!
.signup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 6rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgba(9, 64, 99, 0.1);
  width: 100%;

  img {
    height: 4rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 2.2rem;
    color: $color-black-01;
    font-style: normal;
    font-weight: normal;
  }
}

.form_group {
  display: inline-flex;
}

.form {
  hr {
    margin: 10px 0;
  }

  select {
    padding: 2px;
  }

  select,
  option,
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="date"],
  input[type="phone"],
  input[type="number"],
  input[type="tel"] {
    width: 100%;
    height: 4rem;
    padding: 0.5rem;
    border: 1px solid #c0e5e5;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  select {
    padding: 2px;
  }

  input[type="checkbox"],
  input[type="radio"] {
    vertical-align: middle;
    height: 1.9rem;
    width: 1rem;
    margin: 1rem 1rem 1rem 0;
  }

  input[type="submit"] {
    background-color: #094063;
    border: none;
    color: white;
    padding: 1rem;
    font-weight: 600;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    font-size: 1.4rem;
    border-radius: 4px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    margin-right: 1rem;
  }

  input[type="button"] {
    background-color: $color-pure-white;
    border: 1.5px solid $color-mainblue;
    color: $color-mainblue;
    font-weight: 600;
    width: fit-content;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    font-size: 1.4rem;
    border-radius: 4px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  input[type="file"] {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .form-group {
    margin: 10px 0;
  }

  .hint {
    font-size: 10px;
    color: #aaa;
    font-style: italic;
  }

  .radio {
    padding-left: 24px;
    margin: 10px 0;

    input[type="radio"] {
      margin-left: -24px;
      display: inline-block;
      vertical-align: middle;
    }

    label {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .input-error {
    color: $color-red;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 10px;
  }

  .input-message {
    margin: 15px 0;
    font-size: 1.4rem;
    font-weight: bold;
    color: $color-black;
    display: block;
  }
}

.planner__form {
  max-width: 100%;
  width: 100%;
  display: flex;
  padding: 30px 20px;

  h1 {
    font-size: 32px;
  }

  .intro {
    font-size: 14px;
    margin: 10px 0;
  }

  h1 {
    font-size: 32px;
  }
}

.signup__form {
  max-width: 90%;
  width: 40rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 1.4rem;
  color: $color-black;
}

.sign__form__file-input {
  display: flex;
  flex-direction: column;

  button {
    background-color: #fff;
    border: 1.5px solid #094063;
    color: #094063;
    border-radius: 4px;
    padding: 1rem;
    margin: 1rem 0;
  }
}

//sign up form ends here i think

.button-header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  background: #fffffa;
  width: 80%;
  position: fixed;
  z-index: 5;
}

.dashboard-btn-section {
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  width: 100%;
  background: $color-pure-white;
  // position: fixed;
  // z-index: 5;
  // flex-wrap: wrap;
}

// .tab-host {
//   margin-top: 80px;
// }

//Dashboard begins
// .dashboard_profile {
//   display: flex;
//   //padding: 2rem;
//   border-radius: 4px;
//   // border: 1px solid rgba(9, 64, 99, 0.1);
//   margin: 2rem;
//   justify-content: space-between;
//   flex-direction: column;
//   box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

//   .info {
//     justify-content: space-between;
//     padding: 2rem;
//     display: flex;
//     align-items: center;

//     img {
//       height: 28rem;
//       // border-radius: 50%;
//       // width: 28rem;
//     }
//   }
// }

.profile-section {
  display: flex;
  margin-bottom: 2rem;
  justify-content: flex-start;
  align-items: flex-start;
  // padding-right: 10rem;
}

.profile__img {
  height: 24rem;
  width: 24rem;
  border-radius: 4px;
}

.profile-detail-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  justify-content: space-between;
  padding-top: 0;
}

.profile-item {
  width: 28rem;
  margin-right: 2rem;
}

.profile-item-wrapper {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  min-height: 5rem;
  justify-content: space-between;
  line-height: 2.4rem;
  overflow-wrap: break-word;
}

.dashboard-card {
  background-color: $color-pure-white;
  box-shadow: 0px 4px 4px rgba(81, 153, 211, 0.04);
  border-radius: 4px;
  color: $color-mainblue;
  padding: 2rem;
}

.dashboard-card--pb-large {
  padding-bottom: 10rem;
}

.dashboard-card__focus-text {
  font-size: 2.4rem;
  color: #212121;
  margin-bottom: 2rem;
  font-weight: 800;
}

.dashboard-card__title-text {
  font-size: 1.8rem;
  color: #212121;
  margin-bottom: 2rem;
  font-weight: 800;
}

.dashboard-card__title-text--light {
  @extend .dashboard-card__title-text;
  font-weight: 500;
}

.dashboard-card__body-text {
  font-size: 1.6rem;
  color: #212121;
  margin-bottom: 2rem;
}

.dashboard-card__body-text--bold {
  @extend .dashboard-card__body-text;
  font-weight: bolder;
}

.dashboard-card__data-text {
  font-size: 2.2rem;
  font-weight: 800;
}

.dashboard-card__text--left-align {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.dashboard-card__alert {
  width: 100%;
  padding: $var-sm;
}

.dashboard-card__alert--blue {
  background-color: $color-white-04;
  color: $color-black-03;
}

.unassigned-prod-modal-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin: 2rem 0;
}

.unassigned-prod-modal-wrap__small-text {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 1rem;
}

.contact-us {
  margin-bottom: 2rem;
  background-color: $color-midblue;
  color: $color-white-01;

  h2 {
    font-size: 1.6rem;
  }

  a {
    color: $color-white-01;
  }
}

// Secondary Care

.secondary-care-contact {
  @extend .contact-us;
}

.sc-card-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 20rem;
  margin-bottom: 2rem;
}

.sc-card {
  width: 49%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.sc-card__title-text {
  font-size: 2rem;
  color: $color-black;
}

.sc-card__text {
  font-size: 1.6rem;
  color: $color-black-02;
}

// .contact-us {
//   margin-bottom: 2rem;
//   background-color: $color-midblue;
//   color: $color-white-01;

//   h2 {
//       font-size: 1.6rem;
//   }

//   a {
//       color: $color-white-01;
//   }

//   // padding: 10px;
//   // background: #094063;
//   // color: white;
//   // font-size: 16px;

//   // a {
//   //   color: white;
//   //   margin: 0 4px;
//   // }
// }

.profile-item__label {
  // letter-spacing: 0.3rem;
  // font-size: 1.2rem;
  // padding-bottom: 1rem;
  // font-weight: 300;

  font-size: 1.4rem;
  font-weight: 600;
  color: $color-black-02;
}

.profile-item__text {
  // font-size: 2.0rem;
  // padding-bottom: 2rem;

  font-size: 1.8rem;
  font-weight: 800;
  color: $color-black-01;
  margin-top: 1rem;
}

.full_profile {
  display: none;
  // display: flex;
  border-radius: 4px;
  margin: 2rem;
  justify-content: space-between;
  flex-direction: column;

  img {
    max-width: 28rem;
    width: 100%;
  }
}

// .profile__tab {
//   display: flex;
//   margin: 2rem;
//   flex-wrap: wrap;

//   button {
//     padding: 1rem;
//     letter-spacing: 0.2rem;
//     border: none;
//     background-color: #fffffa;
//     font-weight: 500;
//     font-size: 1.4rem;
//     color: $color-midblue;
//     border-bottom: 4px solid rgba(9, 64, 99, 0.1);
//     cursor: pointer;
//     flex-grow: 1;
//     text-transform: uppercase;
//   }

//   button.activeTab {
//     border-bottom: 4px solid #094063;
//     font-size: 1.6rem;
//     color: $color-mainblue;

//   }

//   button:focus {
//     outline: 0.1px solid rgba(9, 64, 99, 0.1);
//   }
// }

.dashboard-tab {
  justify-content: flex-start;
}

.dashboard-tab__button {
  min-width: 15rem;
  border: 0;
  padding: 1rem;
  font-size: 1.6rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: $color-pure-white;
  margin-right: 1rem;
  cursor: pointer;
}

.dashboard-tab__button:hover {
  background-color: $brand-01-hover;
  color: $color-pure-white;
}

.dashboard-tab__button-active {
  font-weight: 800;
  background-color: $color-mainblue;
  color: $color-pure-white;
}

.full_profile__info {
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem;
}

.profile__info {
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center;
}

.profile__planinfo {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  color: $color-black;
}

.stats-data-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}

.stats-data-card {
  width: 32%;
  height: 12rem;
  display: flex;
  justify-content: space-between;
}

.stats-data-icon {
  display: flex !important;
  align-items: flex-end;
  color: $color-midblue;
}

// .analytic_card {
//   min-width: 32%;
//   border: 1px solid rgba(9, 64, 99, 0.1);
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   border-radius: 4px;
// }

.viewprofile {
  margin-left: 2rem;

  button {
    background-color: $color-mainblue;
    padding: 1rem 2rem;
    border: none;
    border-radius: 4px;

    a {
      color: $color-white;
      text-decoration: none;
      letter-spacing: 0.15rem;
    }
  }
}

.big_num {
  color: #107bc0;
  font-size: 4.8rem;
  font-style: normal;
  font-weight: 500;
}

.smalltext {
  font-size: 1.8rem;
  letter-spacing: 0.05rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  vertical-align: middle;

  input {
    display: none;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $color-midblue;
}

input:focus + .slider {
  box-shadow: 0 0 1px $color-midblue;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.dash__settings {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  padding: 2rem;
  font-size: 1.4rem;
  color: $color-black;

  span {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

// .tips-expanded {
//   display: flex;
//   width: 100%;
//   min-width: 32rem;
//   margin-top: 0.5rem;
//   background-color: $color-white;
//   padding-right: 0.5rem;
//   padding-left: 0.5rem;
//   flex-flow: wrap;
//   flex-direction: column;

//   h2 {
//     font-size: 3.2rem;
//     padding: 1rem;
//     line-height: 4rem;
//     color: $color-mainblue;
//   }

//   p {
//     font-size: 1.6rem;
//     line-height: 2.4rem;
//     padding: 1rem;
//     text-align: justify;
//     color: #333333;
//   }
// }

// .tips-expanded__img {
//   width: 100%;
//   padding: 1rem;

//   img {
//     max-width: 100%;
//     height: auto;
//   }
// }

// .tips__expanded--button {
//   padding: 0rem 1rem 1rem 1rem;

//   button {
//     background-color: $color-white;
//     font-size: 1.4rem;
//     font-weight: normal;
//     font-style: normal;
//     color: $color-midblue;
//     padding-right: 2rem;
//     border: 0;
//   }

//   a {
//     color: $color-midblue;
//     text-decoration: none;
//   }
// }

.feedback_scroll_btn {
  font-size: 20px;
  padding: 1rem;
  position: absolute;
  right: 50%;
  bottom: 12%;
  cursor: pointer;
  z-index: 10;
  background-color: #e3e3e3;
  border-radius: 100%;
  box-shadow: 0 0 3px 0px #868686;
}

.feedback_scroll_btn.show-btn {
  display: block;
}

.feedback_scroll_btn.hide-btn {
  display: none;
}

//chat
// .chat {
//   display: flex;
//   flex-direction: column;
//   padding: 1rem;
//   min-height: 100vh;
//   height: 100%;

//   img {
//     border-radius: 50%;
//     width: 4.2rem;
//     height: 4.2rem;
//   }
// }

// .chat__date {
//   display: flex;
//   justify-content: center;
//   padding: 3rem;
//   color: $color-midblue;
// }

// .enrollee_chat {
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;

//   .message {
//     margin: 1rem;
//     max-width: 60%;
//     word-break: break-all;
//     padding: 1.5rem;
//     height: auto;
//     background-color: $color-mainblue;
//     color: white;
//     font-size: 1.4rem;
//     border-radius: 4px;

//     p {
//       line-height: 2.2rem;
//       font-style: normal;
//       font-weight: 300;
//     }
//   }
// }

// .doctor_chat {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;

//   .message {
//     margin: 1rem;
//     max-width: 60%;
//     word-break: break-all;
//     padding: 1.5rem;
//     height: auto;
//     background-color: $color-midblue;
//     color: white;
//     font-size: 1.4rem;
//     border-radius: 4px;

//     p {
//       line-height: 2.2rem;
//       font-style: normal;
//       font-weight: 300;
//     }
//   }
// }

.page-info-banner {
  width: 100%;
  padding: 15px;
  color: $color-white;
  //position: absolute;
  min-height: 8rem;
  display: flex;
  align-items: center;
  background-color: $color-mainblue;
}

.page-info-banner.error {
  background-color: $color-red;
}

.page-info-banner.sender-info {
  display: flex;
  background: #f5f5f5;
  color: #004c70;
  cursor: pointer;
  border-bottom: 1px solid rgba(9, 64, 99, 0.101961);
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }

  .info {
    margin-left: 1rem;
    flex-grow: 1;

    .sender-name {
      font-size: 1.5rem;
    }
    .action_prompt {
      font-size: 0.9rem;
      font-style: italic;
      font-weight: lighter;
    }
  }

  .fa {
    color: inherit;
  }
}

// .message_time {
//   font-size: 1.0rem;
//   color: #D3D3D3;
// }

// .type_chat-wrapper {
//   position: sticky;
//   bottom: 0;
//   background-color: #FFFFFA;
//   border-top: 1px solid rgba(9, 64, 99, 0.10196078431372549);
//   padding-top: 1rem;

//   .attachment_count {
//     padding-left: 1rem;
//     font-size: 1.3rem;
//     cursor: pointer;
//   }
// }

// Preconsultation form

.dashboard-form__input {
  @extend .profile-item__text;
  border: 1px solid $color-white-05;
  border-radius: 4px;
  padding: 1rem 1.2rem;
  width: 36rem;
  outline: none;
  font-weight: 600;
  font-size: 1.6rem;
}

.dashboard-form__input:focus {
  border: 1px solid $color-midblue-01;
}

.dashboard-form__input--full-width {
  @extend .dashboard-form__input;
  width: 100%;
}

.dashboard-form {
  display: flex;
  flex-direction: column;

  input[type="radio"],
  input[type="checkbox"] {
    margin-right: 1rem;
    font-size: 2rem;
  }
}

.dashboard-form-item-wrapper {
  display: flex;
}

.dashboard-form__btn {
  margin-top: 4rem;
  font-size: 1.6rem;
  font-weight: 800;
  align-self: end;
}

.dashboard-form__checklist-wrap {
  // margin-top: 1rem;
  // height: 4.5rem;
  color: $color-black;
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  width: 36rem;
  flex-direction: column;
}

.dashboard-form-item {
  @extend .profile-item-wrapper;
  width: 36rem;
}

.dashboard-form-item__radio-check-btn {
  display: block;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;

  label {
    padding: 1rem;
    width: 100%;
    display: block;
    text-align: left;
    color: #212121;
    cursor: pointer;
    position: relative;
    z-index: 2;
    overflow: hidden;
    font-size: 1.6rem;
    font-weight: 600;
    border-radius: 4px;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
      background-color: rgba(137, 203, 240, 0.08);
      position: absolute;
      left: 50%;
      top: 50%;
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 24px;
      height: 24px;
      content: "";
      border: 2px solid #094063;
      background-color: #fff;
      background-image: url(../src/img/check.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 68%;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  input:checked ~ label {
    &:before {
      transform: translate(-50%, -50%) scale3d(75, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #094063;
      border-color: #094063;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}

.dashboard-form-item__check-btn {
  label {
    &:after {
      border-radius: 10%;
    }
  }
}

.dashboard-form__select {
  -webkit-appearance: none;
  background: url(../src/img/down_chevron.svg) no-repeat 95% 50%;
}

.dashboard-form__info-text {
  font-size: 1.2rem;
  color: #4f4f4f;
}

.filterable-multiselect {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem 0;

  .input-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    align-items: center;

    .item {
      background: #ccc;
      border-radius: 0.6rem;
      padding: 0.5rem;
      color: #353434;
      font-size: 1rem;
      text-transform: lowercase;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;

      .remove {
        margin-left: 0.7rem;
        font-size: 1rem;
        cursor: pointer;
        color: #353434;
        line-height: 0.8rem;
      }
    }

    .input {
      font-size: 1.2rem;
      font-weight: normal;
      border: none;
      box-shadow: none;
      width: auto;
      flex: 1;
      min-width: 2rem;
      margin: 0;
      min-height: 2rem;

      &:focus {
        outline: none;
      }
    }
  }

  ul.dropdown {
    color: #4f4f4f;
    font-size: 1.2rem;
    margin-top: 0;
    max-height: 21rem;
    overflow-y: scroll;
    border: 1px solid rgba(9, 64, 99, 0.1);
    border-radius: 5px;
    z-index: 9999;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #eeeeee;
    list-style: none;
    padding-left: 0;

    .item {
      cursor: pointer;
      border-bottom: 1px solid #eee;
      padding: 0.5rem;
      background-color: $color-pure-white;

      .text-main {
        font-size: 1.2rem;
      }

      .text-sub {
        font-size: 0.8rem;
        color: #aaa;
      }
    }
  }
}

// Chat
.convo-thread-wrap {
  border-bottom: 1.5px solid #e6f0f9;
  margin-bottom: 2rem;
  cursor: pointer;

  .dashboard-card__title-text {
    margin-bottom: 1rem;
  }
}

.convo-thread-wrap__main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.convo-thread-wrap__message-status {
  font-size: 1.5rem;
  position: relative;
  right: 0.7em;
  border: 3px solid #fff;
  border-radius: 50%;

  &.orange {
    color: orange;
  }

  &.green {
    color: #13940b;
  }

  &.grey {
    color: grey;
  }
}

.empty-state-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.empty-state-btn-wrap {
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.chat-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem;
}

.chat-doctor-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: sticky;
  top: -20px;
  background-color: $color-pure-white;
  padding: 2rem;
  cursor: pointer;
}

.chat-doctor-info {
  display: flex;
  align-items: center;
}

.chat-doctor__img {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}

.chat-doctor-name {
  font-weight: 800;
  color: #212121;
  font-size: 1.6rem;
}

.chat-doctor-prompt {
  font-size: 1.2rem;
  color: #333;
}

.chat-section {
  height: 60vh;
  //height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 2rem 0;
}

.chat-item__date {
  display: flex;
  justify-content: center;
  padding: 3rem;
  color: $color-midblue;
  font-size: 1.4rem;
  font-weight: 600;
}

.enrollee-chat {
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
  min-height: fit-content;
}

.chat-item__message {
  max-width: 60%;
  padding: 1.5rem;
  height: auto;
  font-size: 1.6rem;
  line-height: 2.4rem;
  border-radius: 4px;
}

.enrollee-chat__time {
  color: $color-black-02;
}

.enrollee-chat__bubble {
  background-color: $color-white-04;
  color: $color-black;
}

.doctor-chat {
  display: flex;
  justify-content: flex-start;
  margin: 1rem;
  align-items: flex-end;
  min-height: fit-content;
}

.doctor-chat__bubble {
  background-color: $color-mainblue;
  color: $color-pure-white;
  margin: 1rem;
}

.doctor-chat__title {
  margin-bottom: 2rem;
  font-size: 1.6rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid $color-pure-white;
}

.chat-item__message-time {
  font-size: 1rem;
  margin-top: 2rem;
}

.doctor-chat__time {
  color: $color-white-08;
}

.chat-item__message {
  max-width: 65%;
  font-size: 1.4rem;
  line-height: 2rem;

  p {
    word-break: break-word;
  }
}

.prescription-chat-v2 {
  width: 300px;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 1.5rem;
  border-radius: 10px;
}

.prescription-chat {
  width: 27rem;
  max-width: 100%;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 1.5rem;
  height: auto;
}

@media (min-width: 768px) {
  .prescription-chat {
    width: 66rem;
  }
}

@media (min-width: 1024px) {
  .prescription-chat {
    width: 68rem;
  }
}

@media (min-width: 1440px) {
  .prescription-chat {
    width: 103rem;
  }
}

.chat-input-wrapper {
  display: flex;
  width: 100%;
}

.attachment_count {
  display: flex;
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.chat-input-details {
  flex-direction: column;
}

.chat-input {
  width: 100%;
  display: flex;
  padding: 2rem 0;
  position: sticky;
  bottom: -20px;
  background-color: $color-pure-white;
  border-top: 2px solid;

  input[type="text"] {
    width: 80%;
    border-radius: 4px;
    padding: 2rem;
    background-color: $color-light-white;
    border: 0;
    font-size: 1.6rem;
  }

  button {
    font-size: 1.6rem;
    padding: 2rem;
    width: 15%;
    border: 0;
  }
}

.consultation-tabs {
  background-color: $color-white;
  color: $color-mainblue;
  width: 50%;
  padding: 20px;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  border: none;
  cursor: pointer;
  outline: none;

  &.activeTab {
    background-color: $color-mainblue;
    color: $color-white;
  }
}

.chat-item__new-message-count {
  background-color: $color-mainblue;
  color: $color-white;
  border-radius: 50%;
  font-weight: bolder;
  min-width: 20px;
  height: 20px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Feedback Chat

.feedback-chat-input {
  width: 100%;
  display: flex;
  padding: 2rem 0;
  position: sticky;
  bottom: -20px;
  background-color: $color-pure-white;
  border-top: 2px solid;

  input[type="text"] {
    width: 85%;
    border-radius: 4px;
    padding: 2rem;
    background-color: $color-light-white;
    border: 0;
    font-size: 1.6rem;
  }

  button {
    font-size: 1.6rem;
    padding: 2rem;
    width: 15%;
    border: 0;
  }
}

.chat-input__file-input {
  // width: 5%;
  // display: flex;
  align-items: center;
  background-color: $color-light-white;
  justify-content: center;

  button {
    border: 0;
    font-size: 1.6rem;
    background-color: $color-light-white;
    color: $color-mainblue;
  }
}

.attachment-grid-wrapper {
  max-width: 700px;
  overflow-y: scroll;

  .attachment-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .attachment {
      margin: 1rem;
      position: relative;
      width: 200px;
      height: 200px;

      img {
        width: 100%;
      }

      .delete {
        position: absolute;
        background: #11111159;
        color: white;
        padding: 2rem 2rem 2rem 3rem;
        right: 0;
        font-size: 2rem;
        border-bottom-left-radius: 5rem;
        cursor: pointer;

        .fa {
          color: white;
        }
      }
    }
  }
}

//planner
.planner {
  padding: 1rem;
  color: $color-black;
  min-height: 580px;
  //margin-top:6.5rem;
}

.expired-plan-container {
  display: flex;
  background: $color-light-white;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  .logout-btn-container {
    font-size: 2rem;
    color: white;
    margin: 1rem;
    text-align: right;
    margin-right: 5rem;

    .fa {
      color: white;
    }

    button {
      font-size: 2rem;
      color: white;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: left;
    flex-direction: row;
    //max-width: 250px;
    margin: auto;
    max-width: 50%;
    margin-top: 2rem;
    overflow-y: scroll;

    .content-image {
      max-width: 100%;
    }

    .content-main {
      .primary_button {
        margin-left: 0;
        padding: 1rem 3rem;
      }
    }
  }

  .logo {
    max-width: 50px;
    margin: 2rem auto;

    img {
      max-width: 100%;
    }
  }

  .plan-expiry-notice__header-text {
    color: $color-midblue;
    font-size: 4.5rem;
    line-height: 5.5rem;
    font-weight: 900;
    font-style: normal;
    text-align: center;
    margin-bottom: 25px;
  }

  .plan-expiry-notice__body-text {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-black-03;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 25px;
  }
}

.countdown {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: $color-white-04;
  padding: 15px;
  margin-bottom: 4rem;
  border-radius: 4px;
}

.modal {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal.open {
  display: flex;
}

.modal-content {
  background-color: #fefefe;
  min-height: 30rem;
  margin: auto;
  padding: 2rem;
  width: auto;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
}

.modal-header {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 500;
  color: $color-mainblue;
  text-transform: capitalize;
}

.pharmacy-modal {
  width: auto;
}

.modal-close {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-end;
}

.modal-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(12, 12, 12, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  display: flex;
  padding: 0 3rem;
  z-index: 9999;
  overflow-y: scroll;

  .modal {
    background: #fff;
    width: 800px;
    height: auto;
    margin: auto;
    border-radius: 3px;

    .modal-header {
      padding: 1.5rem;
      background: #f3f3f3;
      display: flex;
      justify-content: space-between;

      .close {
        font-size: 18px;
        margin-right: 2rem;
        align-self: center;
        color: gray;
        font-weight: bolder;
        cursor: pointer;
      }
    }

    .modal-content {
      max-height: 480px;
    }

    .modal-footer {
      padding: 1.5rem;
      border-top: 1px solid #cecece;

      button {
        color: #0a4062;
        font-weight: bolder;
        background: none;
        border: none;
        cursor: pointer;
      }
    }
  }
}

// Settings page

//tooltip styles
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 80%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 80%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

.footnote {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 1.2rem;
  font-size: 1.1rem;
  background-color: #ededed;
  display: flex;
  justify-content: center;
}

.pa-code-modal {
  font-size: 1.3rem;
  text-align: center;

  p {
    margin: 1rem 0;
  }

  .message {
    font-size: 1.7rem;
  }
  .code_holder {
    .code {
      margin: 2rem 0;
      background-color: #f9f9f9;
      padding: 2rem;
      font-weight: bolder;
      border: 1px dashed #ccc;
      border-radius: 0.5rem;
      font-size: 2.5rem;
    }
  }
}

.doctor-profile {
  text-align: center;

  p {
    font-size: 1.5rem;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      border-radius: 100%;
      width: 150px;
      height: 150px;
      margin-bottom: 2rem;
    }

    .name {
      font-size: 3rem;
    }
    .smalltext {
      margin-left: 1rem;
      font-size: 1.2rem;
      font-style: italic;
      font-weight: lighter;
    }

    .profile__infotext {
      padding-bottom: 2rem;
    }
  }

  hr {
    margin: 2rem 1rem;
  }
}

.doctor-profile.small {
  text-align: left;
  border-bottom: 1px solid #eeeeee;
  padding: 2rem;
  cursor: pointer;

  img {
    margin-right: 2rem;
  }

  .info {
    flex-direction: row;

    .profile__label {
      padding-bottom: 0;
    }

    .profile__infotext {
      padding-bottom: 1rem;
    }
  }

  .smalltext {
    margin-left: 0;
  }
}

.alert__banner {
  padding: 2rem 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 1.7rem;
    margin-bottom: 0.8rem;
  }

  p {
    font-size: 1.2rem;
  }

  .dismiss {
    font-weight: bolder;
    cursor: pointer;

    .fa {
      color: white;
    }
  }
}

.alert__banner.success {
  background: #009688;
}

.alert__banner.error {
  background: #ab0404;
}

.calender-event-wrapper {
  padding: 2px 5px;
  background-color: transparent;

  .rbc-event {
    background-color: transparent;
  }

  .event-item {
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
  }

  .event-item.red {
    background: $color-red;
  }

  .event-item.purple {
    background-color: purple;
  }

  .event-item.orange {
    background-color: orange;
  }

  .event-item.green {
    background-color: $color-green;
  }

  .event-item.white {
    background-color: white;
  }
}

@media screen and (max-width: 768px) {
  // Dashboard
  .dashboard__primary-btn,
  .dashboard__secondary-btn {
    font-size: 1.4rem;
  }

  .dashboard-refer__primary-btn {
    width: 100%;
  }

  .dashboard-refer__secondary-btn {
    width: 100%;
  }

  .dashboard-btn-section {
    justify-content: center;
  }

  .stats-data-card {
    width: 100%;
  }

  .stats-data-wrap {
    flex-direction: column;
    min-height: 40rem;
  }

  .dashboard-card__data-text {
    font-size: 1.8rem;
  }

  .dashboard-card__title-text {
    font-size: 1.5rem;
  }

  .dashboard-list-item {
    font-size: 1.4rem;

    a {
      font-size: 1.4rem;
    }
  }

  // Dashboard table
  .dashboard-table-item {
    font-size: 1.4rem;
    padding: 2rem;
  }

  // Tips
  .tips-wrapper {
    flex-direction: column;
  }

  // Tip Page
  .article-wrapper {
    width: 100%;
  }

  .article__title {
    font-size: 2rem;
    line-height: 3rem;
  }

  .article__text {
    p {
      float: none;
    }

    img {
      max-width: 100%;
      float: none;
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }

  .article__img {
    height: 14rem;
  }

  // Pre-consultation form
  .dashboard-form-item-wrapper {
    flex-direction: column;
  }

  .dashboard-input-wrap {
    width: 100%;
  }

  .profile-input-wrap {
    margin-right: 0;
  }

  .dashboard-form__btn {
    margin-top: 3rem;
  }

  .dashboard-form__checklist-wrap {
    width: 100%;
  }

  .dashboard-form-item {
    width: 100%;
  }

  // Chat
  .chat-doctor-wrapper {
    flex-wrap: wrap;
  }

  .chat-input__file-input {
    width: 15%;
  }

  .chat-input {
    input[type="text"] {
      width: 60%;
      padding: 1rem;
      font-size: 1.4rem;
    }

    button {
      font-size: 1.4rem;
      padding: 1rem;
      width: 25%;
    }
  }

  .chat-button-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 1px solid #e6f0f9;
  }

  .chat-section {
    height: 65vh;
  }

  .attachment_count {
    font-size: 1.2rem;
  }

  // Secondary Care
  .sc-card-wrap {
    flex-direction: column;
    min-height: 35rem;
  }

  .sc-card {
    width: 100%;
  }

  // Contact us

  .contact-us {
    h2 {
      font-size: 1.4rem;
    }
  }

  // Feedback
  .feedback-chat-input {
    input[type="text"] {
      width: 75%;
      padding: 1rem;
      font-size: 1.4rem;
    }

    button {
      font-size: 1.4rem;
      padding: 1rem;
      width: 25%;
    }
  }

  .profile-item__text {
    font-size: 1.6rem;
    margin-top: 0.8rem;
  }

  .active {
    display: none;
  }

  .sidebar {
    font-size: 1.4rem;
    min-width: 20rem;

    .sidebar__top {
      height: 8rem;
      display: flex;
      padding-left: 2.5rem;
      align-items: center;
      border-bottom: 1px solid rgba(9, 64, 99, 0.1);

      img {
        min-width: 100%;
      }
    }

    ul {
      width: 100%;
    }

    // .dashboard__menu {
    //   height: 6rem;
    //   padding-left: 2rem;
    //   display: flex;
    //   border-left: 5px solid transparent;
    //   align-items: center;
    //   a {
    //     text-decoration: none;
    //     color: $color-mainblue;
    //     font-weight: 300;
    //   }
    //   .fa {
    //     margin-right: 1rem;
    //   }
    // }

    .dashboard__menu {
      .fa {
        color: #fff;
        font-size: 1.6rem;
        width: 2rem;
      }
    }

    // .menu-active {
    //   border-left: 5px solid $color-mainblue;
    //   background-color: rgba(9, 64, 99, 0.1);
    // }
  }

  // .content {
  // width: 80%;
  // height: 100%;
  // background-color: #FFFFFF;
  // display: flex;
  // flex-direction: column; // min-width: 76.8rem;
  // }

  // Header

  .header {
    padding: 1rem;
  }

  .header__left {
    display: flex;
    align-items: center;

    .toggle-nav {
      display: flex;
      color: $color-mainblue;
      font-size: 2rem;
      border: none;
      margin-right: 1rem;
      background-color: $color-white;
    }
  }

  .header__title {
    h2 {
      font-size: 1.8rem;
    }
  }

  .header__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      border-radius: 3px;
      font-size: 1.2rem;
      min-width: 10rem;
      height: 3rem;
      font-style: normal;
      font-weight: normal;
      background-color: $color-mainblue;
      color: $color-white;
      border: 0;
      margin: 0 1rem;
    }

    .header__notif {
      button {
        width: 2rem;
        height: 2rem;
      }

      .notif {
        border: 0;
        background-color: $color-white;
        color: $color-mainblue; // height: 4rem;
        // width: 4rem;
      }
    }

    .header__search {
      width: 9rem;
      height: 3rem;
      input {
        border-radius: 3px;
        width: 100%;
        height: 100%;
        border: 1px solid $color-mainblue;
        padding: 1rem;
      }
    }
  }

  .hospital_list {
    th {
      font-size: 1.2rem;
    }
    td {
      font-size: 1.4rem;
    }
  }

  .tips {
    // justify-content: center;
    width: 100%;
  }

  .signup__header {
    padding-bottom: 2rem;
    padding-top: 5rem;
    flex-wrap: wrap;

    img {
      height: 2.5rem;
    }

    p {
      font-size: 1.6rem;
    }
  }

  // .dashboard_profile {
  //   flex-direction: column;
  //   padding: 0;
  //   max-width: 100%;
  //   margin: 2rem;

  //   .info {
  //     flex-direction: column;
  //     padding: 0;

  //     img {
  //       align-self: center;
  //       max-width: 100%;
  //       height: auto;
  //     }
  //   }
  // }

  .profile-section {
    flex-direction: column;
  }

  .profile-detail-wrap {
    padding: 0;
  }

  // .button-header {
  //   justify-content: center;
  //   width: 100%;
  // }

  .profile__info {
    padding-top: 4rem;
  }

  .enrollee_analytic {
    height: 68rem;
    justify-content: space-between;
    flex-direction: column;
  }

  .analytic_card {
    height: 20rem;
  }

  .tips-expanded {
    h2 {
      font-size: 2.2rem;
      padding: 1rem;
      line-height: 3rem;
      font-style: normal;
      font-weight: 500;
    }

    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
      padding: 1rem;
      font-style: normal;
      font-weight: 300;
    }
    .chat {
      img {
        width: 3.2rem;
        height: 3.2rem;
      }
    }

    .doctors_chat,
    .enrollee_chat {
      .message {
        font-size: 1.2rem;
        p {
          line-height: 2rem;
        }
      }
    }
  }

  body .fc {
    font-size: 1.2rem;
  }

  .expired-plan-container {
    .content {
      flex-direction: column;
      max-width: 80%;
      justify-content: normal;

      .content-image {
        display: none;
      }
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .attachment-grid-wrapper {
    max-width: 100%;
    .attachment-grid {
      .attachment {
        width: 100px;
        height: 100px;

        .delete {
          padding: 1rem 1rem 1rem 2rem;
          font-size: 1.5rem;
          border-bottom-left-radius: 3rem;
        }
      }
    }
  }

  .doctor-profile.small {
    padding: 2rem 0;

    img {
      width: 100px;
      height: 100px;
      margin-right: 2rem;
    }

    .info {
      .profile__label {
        padding-bottom: 0;
      }

      .profile__infotext {
        font-size: 1.6rem;
        padding-bottom: 1rem;
      }
    }

    .smalltext {
      margin-left: 0;
    }
  }

  // .welcome-wrapper {

  //   padding: 2rem;

  //   h1 {
  //     font-size: 5rem;
  //   }

  //   h4 {
  //     font-size: 1.8rem;
  //   }

  //   ul.action-chooser {
  //     justify-content: center;
  //     flex-direction: column;
  //     overflow-y: scroll;
  //     margin-bottom: 10rem;

  //     .action-item {
  //       min-width: 120px;
  //       padding: 1rem;

  //       .action-item__icon {
  //         width: 80px;
  //         height: 80px;
  //       }

  //       .action-item__label {
  //         margin-top: 1rem;
  //         font-size: 3rem;
  //       }
  //     }
  //   }
  // }

  .dashboard-control__button {
    font-size: 1.4rem;
    width: auto;
  }
}

.dashboard-refer-banner {
  margin-bottom: 2rem;
  background-color: #fff;
  border: solid 1px #44a3e6;
  padding: 2px;
}

.dashboard-refer-banner__button {
  background: none;
  width: 100%;
  text-align: left;
  border: none;
  border-left: solid #44a3e6;
  padding: 1.5rem 1rem;

  .fa {
    color: #44a3e6;
    margin-right: 1.2rem;
  }
}

.refer-dashboard-section {
  padding: 1em 0;
  margin-bottom: 2em;
}

.refer-dashboard-section--fixed-width {
  @extend .refer-dashboard-section;
  max-width: 300px;
}

.refer-dashboard-overview__holder {
  display: flex;
  flex-wrap: wrap;
}

.refer-dashboard-phone-numbers {
  margin-bottom: 5em;
}

.refer-dashboard-overview-card {
  border: 1px solid $color-white-05;
  width: 33%;
  margin-right: 3%;
  margin-bottom: 3%;
}

.refer-dashboard-overview-card--full-width {
  @extend .refer-dashboard-overview-card;
  width: 100%;
}

.item__badge {
  background-color: $color-red;
  color: $color-white-02;
  padding: 2px;
  border-radius: 5px;
  font-weight: 800;
  font-size: 1rem;
  margin: -15px 5px 0 5px;
}

@media screen and (max-width: 320px) {
  .profile {
    img {
      height: auto;
    }
  }

  .planner {
    min-height: 320px;
  }
  .refer-dashboard-filter-section {
    flex-direction: column;
  }

  .referral-search-bar {
    width: 100%;
    margin-bottom: 10px;
  }

  .referral-dashboard-form__select {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 500px) {
  // CHAT

  .chat {
    height: 100%;
  }

  // PROFILE

  .profile__img {
    width: 100%;
    height: auto;
  }

  .refer-dashboard-overview-card {
    width: 100%;
  }
}

.hidden {
  display: none;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.pd-left-sm {
  padding-left: $var-sm;
}

.pd-right-sm {
  padding-right: $var-sm;
}

.pd-top-sm {
  padding-top: $var-sm;
}

.pd-bottom-sm {
  padding-bottom: $var-sm;
}

// .feedback-thread-list {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 40px;

//   .feedback-thread-card {
//     display: flex;
//     width: 90%;
//     padding: 30px;
//     color: #094063;
//     margin-bottom: 20px;
//     background-color: #f9f9f9;
//     border-radius: 10px;
//     text-decoration: none;

//     &:hover {
//       transform: scale(1.05);
//       z-index: 2;
//     }
//   }

//   .feedback-thread-title {
//     font-weight: 600;
//   }

//   .feedback-thread-content {
//     font-weight: 300;
//   }

//   .feedback-thread-title, .feedback-thread-content {
//     font-size: 1.6rem;
//     width: 20rem;
//     // margin-right: 1.3rem;
//   }
// }

// .feedback-thread-detail-card {
//   margin: 0 auto;
//   margin-top: 40px;
//   border-radius: 4px;
//   background-color: #f9f9f9;
//   width: 90%;

//   .feedback-thread-detail {
//     padding: 30px;
//     color: #094063;
//     font-size: 1.6rem;
//     font-weight: 600;
//   }

//   .feedback-thread-detail > h2, p {
//     margin-bottom: 1em;
//   }

//   h2 {
//     color: #212121;
//   }

//   p {
//     color: #4B4B4B;
//   }

//   a {
//     background-color: #094063;
//     color: white;
//     border-radius: 4px;
//     font-size: 15px;
//     font-weight: 600;
//     padding: 10px 40px;
//     text-decoration: none;
//   }
// }

.pagination__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination__btn {
  background-color: transparent !important;
  border: none;
  border-radius: none;
  margin: 5px;
}

.pagination__btn_current {
  background-color: transparent !important;
  border: none;
  border-radius: none;
  margin: 5px;
  border-bottom: 1px solid $color-black;
}

.refer-dashboard-filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.referral-dashboard-form__select {
  @extend .dashboard-form__select;
  border: 1px solid #e5e5e5;
  padding: 1rem 1.7rem;
  color: #212121;
  font-weight: 400;
  background: url("./img/caret.svg") no-repeat 95% 50%;
  margin-right: 0;
}

.referral-search-bar {
  background: url("./img/search.svg") no-repeat 5% 50%;
  border-radius: 3px;
  height: 100%;
  border: 1px solid #e5e5e5;
  padding: 1rem;
  padding-left: 3rem;
  color: #4f4f4f;
}

///////////////////////////////////////////////////////
///
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #000;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  z-index: 99;
  align-items: center;
  justify-content: center;
}

.modal-dialog-header {
  overflow-y: auto;
  overflow-x: auto;
  width: auto;
  color: #333;
  font-size: 20px;
  font-family: Avenir LT Std;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.15px;
  border-bottom: double;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 1em;
  padding-top: 1em;
  letter-spacing: 1.5px;
}

.modal-dialog {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  overflow: scroll;
  max-height: 90vh;
  @media (min-width: 750px) {
    max-height: 90vh;
    max-width: 450px;
  }
}

.modal-close-button {
  font-size: 1em;
  font-weight: 400;
  // text-shadow: -1px -1px 0px rgba(255, 255, 255, 0.3), 1px 1px 0px rgba(0, 0, 0, 0.8);;
  cursor: pointer;
  color: #000;
  float: right;
}

.modal-dialog-content {
  height: 90%;
  padding: 20px;
  color: #000;
  overflow-y: scroll;
}

.emptySlot__wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.emptySlot__wrapper h5 {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.emptySlot__wrapper p {
  font-size: 16px;
}

.add-family-member-button-container {
  margin-left: auto;
}

.add-family-member-button-container > button {
  font-weight: 600;
}

.telemedicine-abuse li {
  list-style-type: none; /* Remove default bullets */
  position: relative; /* For positioning custom bullets */
  padding-left: 30px; /* Space for custom bullet */
  line-height: 1.7em;
}

.telemedicine-abuse li::before {
  content: "•"; /* Custom bullet symbol */
  color: #1e293b; /* Custom color */
  font-size: 1.5em; /* Custom size */
  position: absolute;
  padding-left: 10px;
  left: 0;
  top: 0;
}
