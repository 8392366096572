@font-face {
	font-family: 'Avenir LT Std';
	src: url('font/hinted-AvenirLTStd-Black.eot');
	src: url('font/hinted-AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
	url('font/hinted-AvenirLTStd-Black.woff2') format('woff2'),
	url('font/hinted-AvenirLTStd-Black.woff') format('woff'),
	url('font/hinted-AvenirLTStd-Black.ttf') format('truetype'),
	url('font/hinted-AvenirLTStd-Black.svg#AvenirLTStd-Black') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir LT Std';
	src: url('font/hinted-AvenirLTStd-Heavy.eot');
	src: url('font/hinted-AvenirLTStd-Heavy.eot?#iefix') format('embedded-opentype'),
	url('font/hinted-AvenirLTStd-Heavy.woff2') format('woff2'),
	url('font/hinted-AvenirLTStd-Heavy.woff') format('woff'),
	url('font/hinted-AvenirLTStd-Heavy.ttf') format('truetype'),
	url('font/hinted-AvenirLTStd-Heavy.svg#AvenirLTStd-Heavy') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir LT Std';
	src: url('font/hinted-AvenirLTStd-Book.eot');
	src: url('font/hinted-AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
	url('font/hinted-AvenirLTStd-Book.woff2') format('woff2'),
	url('font/hinted-AvenirLTStd-Book.woff') format('woff'),
	url('font/hinted-AvenirLTStd-Book.ttf') format('truetype'),
	url('font/hinted-AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir LT Std';
	src: url('font/hinted-AvenirLTStd-Roman.eot');
	src: url('font/hinted-AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
	url('font/hinted-AvenirLTStd-Roman.woff2') format('woff2'),
	url('font/hinted-AvenirLTStd-Roman.woff') format('woff'),
	url('font/hinted-AvenirLTStd-Roman.ttf') format('truetype'),
	url('font/hinted-AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir LT Std';
	src: url('font/hinted-AvenirLTStd-Light.eot');
	src: url('font/hinted-AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'),
	url('font/hinted-AvenirLTStd-Light.woff2') format('woff2'),
	url('font/hinted-AvenirLTStd-Light.woff') format('woff'),
	url('font/hinted-AvenirLTStd-Light.ttf') format('truetype'),
	url('font/hinted-AvenirLTStd-Light.svg#AvenirLTStd-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir LT Std';
	src: url('font/hinted-AvenirLTStd-Medium.eot');
	src: url('font/hinted-AvenirLTStd-Medium.eot?#iefix') format('embedded-opentype'),
	url('font/hinted-AvenirLTStd-Medium.woff2') format('woff2'),
	url('font/hinted-AvenirLTStd-Medium.woff') format('woff'),
	url('font/hinted-AvenirLTStd-Medium.ttf') format('truetype'),
	url('font/hinted-AvenirLTStd-Medium.svg#AvenirLTStd-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}
