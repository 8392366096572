$color-black: #212121;
$color-black-01: #212121; 
$color-black-02: #4F4F4F; 
$color-black-03: #333333;

$color-white: #FFFFFA;
$color-pure-white: #FFFFFF;
$color-light-white: #F8F8F8;
$color-white-01: #FFF;
$color-white-02: #FDFEFE;
$color-white-03: #F2F2F2;
$color-white-04: #E6F0F9;
$color-white-05: #E5E5E5;
$color-white-06: #C4C4C4;
$color-white-07: #FBFBFB;
$color-white-08: #F9FAFC;
$color-white-09: #819FB1;
$color-white-10: #FBFCFD;
$color-white-11: #EEE;


$color-bg-blue: #89CBF0;
$color-mainblue: #094063;
$color-midblue: #107BC0;
$color-midblue-01: #5199D3; 
$color-light-blue: #89CBF0; 
$color-aqua-blue: #C0E5E5; 

$color-red: #ab0404;
$color-green: #13940b;

$brand-01-hover: #175171;
$brand-01-focus: #093B5A;
$brand-01-disabled: #8FA8B8;

$var-sm:5px;
$var-md:15px;
$var-lg:30px;
$var-xl:45px;
