.card-title {
   font-family: Avenir LT Std;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}

.card-pharmacy-span {
  font-family: Avenir LT Std;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.10000000149011612px;
  text-align: left;
}

.card-options-container {
    display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 10px;
  margin-bottom: 10px;

  font-family: Avenir LT Std;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.10000000149011612px;
  text-align: left;
}

.card-container {
  background-color: #E7F2F9;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
}

.card-header-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.card-recommended-container {
   background-color: #137cc1;
  color: white;
  border-radius: 15px 15px 0px 0px;
  text-align: center;

  font-family: Avenir LT Std;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.10000000149011612px;
}