/* Define color variables that can be customized if needed */
:root {
  --checked-color: #094063; /* Color when the checkbox is checked */
  --unchecked-color: white; /* Color when the checkbox is unchecked */
}

/* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  border-radius: 10px;
}

/* Custom checkbox style */
.custom-checkbox {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: var(--unchecked-color); /* Unchecked background color */
  position: relative;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 7px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* The checkmark, hidden by default */
.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  border-radius: 7px;
  visibility: hidden;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  visibility: visible;
  background-color: var(--checked-color); /* Checked background color */
}

/* Optional: Add hover effect */
.custom-checkbox:hover {
  border-color: var(--checked-color);
}
